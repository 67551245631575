<template lang="pug">
nav(aria-label='Breadcrumb' class='text-xs overflow-x-auto whitespace-nowrap')
  template(v-for='(crumb, index) in crumbs' :key='crumb.title')
    UniversalLink(
      :link='$router.resolve(crumb.urlData).href'
      :aria-current='index == crumbs.length - 1 ? "page" : false'
      class='[&:not(:last-child)]:after:content-["_/_"] text-gray-dark hover:no-underline'
    )
      InlineHtml(:text='crumb.title')
</template>

<script setup lang="ts">
const { $sitewideConfig } = useNuxtApp()
const router = useRouter()
const routeBuilder = useRouteBuilder()

const props = defineProps({
  crumbs: {
    type: Array,
    required: true,
    default: () => [],
  },
})

const schema = computed(() => {
  const obj = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  }

  props.crumbs.forEach((crumb, index) => {
    obj.itemListElement.push({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': appendTrailingSlash(
          'https://' + $sitewideConfig.domain + router.resolve(routeBuilder.formatRoute(crumb.urlData)).href
        ),
        name: crumb.title,
      },
    })
  })

  return JSON.stringify(obj)
})

useHead({
  script: [{ type: 'application/ld+json', innerHTML: schema, tagPosition: 'bodyClose' }],
})
</script>
